<!--联系我们-->
<template>
  <div class="contactus">
    <div class="box-css">
      <div class="p-css">
        <p>中国-天津市东丽区弘晟道16号</p>
        <p>俄罗斯-</p>
        <p>哈萨克斯坦-</p>
        <p>蒙古-</p>
      </div>
      <div class="p-css p-css-two">
        <p><span>{{ $t('mi.phone') }}:</span> &nbsp; cs123456789</p>
        <p><span>{{ $t('mi.phone') }}:</span> &nbsp; cs123456789</p>
        <p><span>{{ $t('mi.phone') }}:</span> &nbsp; cs123456789</p>
        <p><span>{{ $t('mi.phone') }}:</span> &nbsp; cs123456789</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ContactUs"
  }
</script>

<style scoped lang="scss">
.contactus {
  width: 100%;
  height: 500px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(196,196,196,0.78);
  .box-css {
    padding: 1rem;
    border: 0.5rem double #d4d0d0;
    transform: scale(1.5);
    background-color: #eeeeee;
  }
  .p-css {
    text-align: left;
    float: left;
  }
  .p-css-two {
    margin-left: 1rem;
  }
}

</style>
